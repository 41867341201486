@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@700&family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Quicksand:wght@300&display=swap');

:root {
  background-color: #ffffff;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="text"], input[type="select"] {
  outline: none !important;
}

.main-container {
  min-height: calc(100vh - 376px);
}

.lulu-kabab {
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  font-style: normal;
}

.mediterranean-cuisine {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.slogan {
  font-family: "Lobster", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.course-heading {
  position: sticky;
  top: 56px;
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  font-style: normal;
}

.is-vegetarian {
  position: relative;
  top: -6px;
  left: 10px;
  margin-left: -26px;
  z-index: -1;
}

.fade-in {
  opacity: 0;
}

.fade-in.is-visible {
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

.carousel .slide .legend {
  bottom: 10px !important;
  padding: 4px 10px !important;
  color: #000000 !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1 !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.editing {
  display: none;
}

.control-next {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.control-prev {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.table :where(th, td) {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.toggle:checked {
  --tglbg: #0000cc;
}
